import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { DecorativeCard } from '@/components/common/DecorativeCard';
import { Heading, Paragraph } from '@/components/common/MarkUp';
import { Section, WidthConstraint } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';
import { getButtonComponentThemeSolid, getTextColour } from '@/lib/colourUtils';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type { SanityAudienceType, SanityCtaBannerType } from '@/types/sanity';
import type { BlockWithAudience, TrackingTagType } from '@/types/shared';

export interface CtaBannerProps
  extends Omit<SanityCtaBannerType, 'CTA'>,
    BlockWithAudience {
  CTA?: {
    _type: 'CTA';
    ctaText: string;
    audienceRef?: SanityAudienceType;
    noAudienceToggle?: boolean;
    ctaLink: string;
    trackingTag?: TrackingTagType;
    accessibleLabel: string;
  };
}

const CtaBanner = ({
  tag,
  backgroundColour,
  // this is to avoid a migration, the two field names were mixed up
  heading: caption,
  caption: heading,
  details,
  CTA: {
    ctaLink,
    ctaText,
    trackingTag,
    accessibleLabel,
    audienceRef,
    noAudienceToggle,
  },
  blockBackgroundColour,
  blockSpacing,
  paddingAdjustment,
  decoration,
  pageAudience,
  isAudienceSwitcherEnabled,
}: CtaBannerProps) => {
  const blockBackgroundColourToken = blockBackgroundColour?.token;
  const backgroundColourToken = backgroundColour?.token || '--surface-primary';
  const isDesktop = useMediaQuery(screen.smQuery);

  ctaLink = blockUrlWithAudience(
    ctaLink,
    audienceRef,
    pageAudience,
    noAudienceToggle,
    isAudienceSwitcherEnabled,
  );

  return (
    <StyledSection
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={blockBackgroundColourToken}
      breakout
      className="cta-banner"
    >
      <StyledWidthConstraint>
        <DecorativeCard
          decoration={decoration}
          backgroundColour={backgroundColour}
        >
          <ContentContainer>
            <Stack>
              {tag?.tagText && (
                <Badge bgColour={tag?.tagColour} margin="0" size="md">
                  {tag.tagText}
                </Badge>
              )}
              {caption && (
                <Paragraph
                  colour={getTextColour(backgroundColourToken, {
                    variation: 'subtle',
                  })}
                  size="label-md"
                >
                  {caption}
                </Paragraph>
              )}
              {heading && (
                <Heading
                  size="heading-lg"
                  colour={getTextColour(backgroundColourToken, {
                    variation: 'strong',
                  })}
                >
                  {heading}
                </Heading>
              )}
              {details && (
                <Body>
                  <Paragraph
                    size="subheading-sm"
                    colour={getTextColour(backgroundColourToken, {
                      variation: 'subtle',
                    })}
                  >
                    {details}
                  </Paragraph>
                </Body>
              )}
            </Stack>
            <ActionContainer>
              <CtaButton
                href={ctaLink}
                variant={getButtonComponentThemeSolid(backgroundColourToken)}
                iconPosition="right"
                aria-label={accessibleLabel}
                className={
                  trackingTag?.blockName &&
                  `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
                }
                size="lg"
                icon={isDesktop ? 'ArrowRight' : null}
              >
                {ctaText}
              </CtaButton>
            </ActionContainer>
          </ContentContainer>
        </DecorativeCard>
      </StyledWidthConstraint>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  margin-left: auto;
  margin-right: auto;
`;

const StyledWidthConstraint = styled(WidthConstraint)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-component-xl);
  width: 100%;
  z-index: 2;
  position: relative;
  ${screen.sm} {
    flex-direction: row;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-self: flex-end;
  width: 100%;
  ${screen.sm} {
    width: auto;
  }
`;

const Body = styled.div`
  ${screen.md} {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
`;

const CtaButton = styled(Button)`
  margin-top: var(--spacing-small);
  position: relative;
  width: 100%;
  justify-content: center;
  ${screen.sm} {
    width: max-content;
  }
  ${screen.md} {
    margin-top: 0;
  }
`;

export { CtaBanner };
